const PATH_ETIQUETTE = 'm203.47,22.61l0,19.2l0,0c0,7.15 -6.41,13 -14.31,13l-160.16,0l-17,36l3.2,-36c-8,0 -14.39,-5.85 -14.39,-13l0,-11l0,0l0,-17c0,-7.16 6.4,-13 14.31,-13l174,0c7.9,0 14.31,5.8 14.31,13l0.04,8.8z'


MD.Import = function(editor, svgCanvas){
  const workarea = document.getElementById("workarea");
  const $importInput = $('#tool_import + input');
  const $openInput = $('#tool_open + input');
  let idtemp = 0;

  $('#tool_import').on("click", place);
  $('#tool_open').on("click", open);

  $('.list_svg_img').on('click', buildEtiquette)

  $importInput.change(importImage);
  $openInput.change(openImage);

  /* __________________ */


  function buildEtiquette(alias) {
    const svg = buildLabelSvg(alias);
    importLabel(svg);
  }

  async function getPicto(alias = 'gr_habitation_eau') {
    let response = await fetch(`./assets/svg/plan/${alias}.svg`);

    if(response.status != 200) {
      throw new Error("Server Error");
    }

    // read response stream as text
    let text_data = await response.text();

    return text_data;
  }

  async function buildLabelSvg(label) {
    const svg = createNode('svg', {
      "width": 204,
      "height": 91.5,
      viewBox: '0 0 204.27 91.15'
    });

    svg.setAttribute('xmlns', "http://www.w3.org/2000/svg")

    const mainGroup = createNode('g', {});
    const textGroup = createNode('g', {
      'class': 'textGroupNode'
    });
    const pictoGroup = createNode('g', {
      transform: 'translate(5 8) scale(.15)'
    });

    const etiquette = createNode('path', {
      "fill": '#fff',
      "stroke": "#1a171b",
      "stroke-width" :"1.61",
      "opacity": 0.8,
      "d": PATH_ETIQUETTE
    });

    const title = createNode('text', {
      "font-style": "normal",
      "font-weight": "bold",
      "text-anchor": "start",
      "font-family": "sans-serif",
      "font-size": "10",
      "y": "22",
      "x": "56",
      "stroke-width": "0",
      "stroke": "#1a171b",
      "fill": "#000000",
      "class": 'textNode'
    });

    const subTitle = createNode('text', {
      "font-style": "normal",
      "text-anchor": "start",
      "font-family": "sans-serif",
      "font-size": "10",
      "y": "36",
      "x": "84",
      "stroke-width": "0",
      "stroke": "#1a171b",
      "fill": "#000000",
      "class": 'textNode'

    });

    const picto = await getPicto(label.picto);

    var parser = new DOMParser();
    var doc = parser.parseFromString(picto, "image/svg+xml");

    pictoGroup.appendChild(Array.from(doc.childNodes[0].children).filter(a => a.nodeName === 'g')[0]);

    title.innerHTML = label.title;
    subTitle.innerHTML = label.subtitle;

    textGroup.appendChild(title);
    textGroup.appendChild(subTitle);

    svg.appendChild(etiquette);
    svg.appendChild(pictoGroup);
    svg.appendChild(textGroup);

    svg.appendChild(mainGroup);
    // document.getElementById('pictoCustom').appendChild(svg)

    return svg;
  }


  function createNode(n, v) {
    n = document.createElementNS("http://www.w3.org/2000/svg", n);
    for (var p in v)
      n.setAttributeNS(null, p, v[p]);
    return n
  }

  function importZoom(svgElement) {
    svgCanvas.importSvgZoom(svgElement.outerHTML);
    svgCanvas.groupSelectedElements();
    svgCanvas.ungroupSelectedElement();
    svgCanvas.alignSelectedElements("c", "page");
    svgCanvas.alignSelectedElements("m", "page");
  }

  function importLabel(svgElement) {
    svgCanvas.importSvgString(svgElement.outerHTML);
    svgCanvas.ungroupSelectedElement();
    svgCanvas.alignSelectedElements("c", "page");
    svgCanvas.alignSelectedElements("m", "page");
  }

  $('#importImageBtn').on('click', importBackground)
  $('#importImageSubBtn').on('click', importZoomImage)

  function importReservedArea(position) {
    return new Promise((resolve) => {
      const width = (13465 / 100) * 26.32;
      const height = (9921 / 100) * 14.29;

      const [x, y] = position.split(',').map(a => Number(a));

      svgCanvas.addSvgElementFromJson({
        element: 'rect',
        attr: {
          x: x,
          y: y,
          fill: '#fff',
          opacity: .5,
          id: svgCanvas.getNextId(),
          width: width,
          height: height,
          class: 'ZOOM',
        }
      }, true);


      resolve();
    });
  }

  function importZoomImage(file, position, zoomType) {
    return new Promise((resolve, reject) => {
      const img_width = 3542.79;
      const img_height = 3542.79;

      var reader = new FileReader();
      reader.onloadend = function(e) {
        // lets insert the new image until we know its dimensions
        const [x, y] = position.split(',').map(a => Number(a));

        const margin = 20;
        const group = svgCanvas.addSvgElementFromJson({
          element: 'g',
          attr: {
            id: svgCanvas.getNextId(),
            class: 'ZOOM'
          }
        }, true);

        const rect = svgCanvas.addSvgElementFromJson({
          element: 'rect',
          attr: {
            x: x,
            y: y,
            fill: '#fff',
            rx: margin,
            ry: margin,
            id: svgCanvas.getNextId(),
            width: img_width,
            height: img_height,
            class: 'ZOOM ZOOM_RECT',
            'data-zoomType': zoomType
          }
        }, true);

        const newImage = svgCanvas.addSvgElementFromJson({
          element: 'image',
          attr: {
            x: x + margin,
            y: y + margin,
            width: img_width - margin*2,
            height: img_height - margin*2,
            id: svgCanvas.getNextId(),
            class: 'ZOOM ZOOM_IMAGE',
            'data-zoomType': zoomType
          }
        }, true);

        group.append(rect);
        group.append(newImage);

        svgCanvas.setHref(newImage, e.target.result);//


        resolve({
          width: img_width,
          height: img_height,
          svgElement: newImage
        });


        // // put a placeholder img so we know the default dimensions
        // var img_width = 100;
        // var img_height = 100;
        // var img = new Image()
        // img.src = e.target.result;
        // img.id = String(idtemp += 1);
        // document.body.appendChild(img);
        // img.onload = function() {
        //   img_width = img.offsetWidth
        //   img_height = img.offsetHeight
        //   insertNewImage(3542.79, 3542.79);
        //   document.body.removeChild(img);
        // };
        //
        // img.onerror = function (e) {
        //   console.log('[Image] -> onerror(ZOOM) -> ', zoomType, e)
        //   reject(e)
        // }
      };

      reader.readAsDataURL(file)
    })
  }

  this.importZoom = importZoom

  function importBackground(file) {
    return new Promise((resolve, reject) => {

      // const file = $('#importImage')[0].files[0];
      const svgBackground = $('#canvasBackground')[0];

      var reader = new FileReader();
      reader.onloadend = function(e) {
          var newImage = svgCanvas.addSvgElementFromJson({
            "element": "image",
            "attr": {
              "x": 0,
              "y": 0,
              "width": '100%',
              "height": '100%',
              "id": svgCanvas.getNextId(),
              "style": "pointer-events:none"
            }
          });

          // Les dimension du canvas sont faite manuellement
          //$('#canvas_width')[0].value = img_width;
          //$('#canvas_height')[0].value = img_height;

          svgCanvas.setHref(newImage, e.target.result);//

          if (!!svgBackground) {
            svgBackground.append(newImage);
          }

          editor.panel.updateContextPanel();

          resolve();
        };

      reader.readAsDataURL(file)

    });
  }

  this.importBackground = importBackground

  function importPlanLabels(file){
    const reader = new FileReader();
    reader.onload = function(e) {

      if (!e.total) {
        return
      }

      let svgNotContainEtiquettes = e.target.result.includes("<g>\n  <title>Layer 1</title>\n </g>\n");

      svgCanvas.importSvgString(e.target.result, true);

      if (!svgNotContainEtiquettes){
        svgCanvas.ungroupSelectedElement();
        svgCanvas.ungroupSelectedElement();
        svgCanvas.ungroupSelectedElement();
      }else{
        svgCanvas.ungroupSelectedElement();
      }

    };
    reader.readAsText(file);
  }



  /* __________________ */

  function importImage(e, fileCustom){

    e.stopPropagation();
    e.preventDefault();

    if (!window.FileReader) return;
    //e.stopPropagation();
    //e.preventDefault();
    workarea.removeAttribute("style");
    $('#main_menu').hide();
    var file = null;
    if (e.type === "drop") file = e.dataTransfer.files[0]
    else file = fileCustom || this.files[0];
    if (!file) return $.alert("File not found");
    if (file.type.indexOf("image") === -1) return $.alert("File is not image");

    //svg handing
    if(file.type.indexOf("svg") != -1) {
      var reader = new FileReader();
      reader.onloadend = function(e) {
        svgCanvas.importSvgString(e.target.result, true);
        svgCanvas.ungroupSelectedElement()
        svgCanvas.ungroupSelectedElement()
        svgCanvas.groupSelectedElements()
        svgCanvas.alignSelectedElements("m", "page")
        svgCanvas.alignSelectedElements("c", "page")
      };
      reader.readAsText(file);
    }

    //image handling
    else {
      var reader = new FileReader();
      reader.onloadend = function(e) {
        // lets insert the new image until we know its dimensions
        insertNewImage = function(img_width, img_height){
          var newImage = svgCanvas.addSvgElementFromJson({
            "element": "image",
            "attr": {
              "x": 0,
              "y": 0,
              "width": img_width,
              "height": img_height,
              "id": svgCanvas.getNextId(),
              "style": "pointer-events:inherit"
            }
          });
          svgCanvas.setHref(newImage, e.target.result);
          svgCanvas.selectOnly([newImage])
          svgCanvas.alignSelectedElements("m", "page")
          svgCanvas.alignSelectedElements("c", "page")
          editor.panel.updateContextPanel();
        }
        // put a placeholder img so we know the default dimensions
        var img_width = 100;
        var img_height = 100;
        var img = new Image()
        img.src = e.target.result
        document.body.appendChild(img);
        img.onload = function() {
          img_width = img.offsetWidth
          img_height = img.offsetHeight
          insertNewImage(img_width, img_height);
          document.body.removeChild(img);
        }
      };
      reader.readAsDataURL(file)
    }

  }

  function loadSvgString(str, callback) {
    var success = svgCanvas.setSvgString(str) !== false;

    callback = callback || $.noop;
    if(success) {
      callback(true);
    } else {
      $.alert("Error: Unable to load SVG data", function() {
        callback(false);
      });
    }
  }

  function openImage(e){
    const f = this;
    if(f.files.length === 1) {
      svgCanvas.clear();
      var reader = new FileReader();
      reader.onloadend = function(e) {
        loadSvgString(e.target.result);
        editor.saveCanvas();
        editor.canvas.update(true);
      };
      reader.readAsText(f.files[0]);
    }
  }

  function onDragEnter(e) {
    e.stopPropagation();
    e.preventDefault();
    // workarea.style.transform = "scale(1.1)";
  }

  function onDragOver(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  function onDragLeave(e) {
    workarea.removeAttribute("style");
    e.stopPropagation();
    e.preventDefault();
  }

  function place(){
    $importInput.trigger("click");
  }

  function open(){
    $openInput.trigger("click");
  }

  workarea.addEventListener('dragenter', onDragEnter, false);
  workarea.addEventListener('dragover', onDragOver, false);
  workarea.addEventListener('dragleave', onDragLeave, false);
  workarea.addEventListener('drop', importImage, false);

  this.place = place;
  this.open = open;
  this.buildEtiquette = buildEtiquette;
  //this.buildLabelSvg = buildLabelSvg;
  this.importBackground = importBackground;
  this.importLabel = importLabel;
  this.importZoomImage = importZoomImage;
  this.importReservedArea = importReservedArea;
  this.importZoom = importZoom;
  this.importPlanLabels = importPlanLabels;

}
